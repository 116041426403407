<template>
  <v-container class="py-12">
    <!--texto ja corrigido-->
    <div class="d-flex flex-column justify-center align-center text-center">
      <div class="text-h4 font-weight-bold mb-2">
        <span> Cadastre-se para ter acesso total a nossa plataforma </span>
      </div>
      <div class="text-body-1 subtext--text">
        <span>
          Sua aplicação não será processada até que você envie toda documentação
          solicitada pela nossa equipe de compliance.
        </span>
      </div>
    </div>

    <v-divider class="my-6 mx-12" />

    <v-row>
      <v-col cols="12" md="3">
        <v-card class="pa-4">
          <v-list-item
            v-for="(item, i) in _tabIndex"
            class="rounded-lg mb-4"
            :key="i"
            :class="{ highlight: step === i }"
            dense
          >
            <v-list-item-icon>
              <v-icon :color="step === i ? 'primary' : 'overtext'">
                {{ item.icon }}
              </v-icon>
            </v-list-item-icon>

            <v-list-item-title
              :class="step === i ? 'primary--text' : 'overtext--text'"
            >
              {{ item.label }}
            </v-list-item-title>
          </v-list-item>
        </v-card>
      </v-col>

      <v-col cols="12" md="9">
        <v-card class="pa-8 mb-8">
          <v-window v-model="step">
            <!-- personal data -->
            <v-window-item>
              <v-form v-model="valid[0]" ref="form1">
                <v-row no-gutters>
                  <v-col cols="12" md="6" class="pr-md-2">
                    <custom-input
                      v-model="formPersonal.name"
                      ref="name"
                      label="Nome completo ou razão social"
                      placeholder="Seu nome / razão social"
                      outlined
                      @keypress="blockNumber($event)"
                    />
                  </v-col>

                  <v-col cols="12" md="6" class="pl-md-2">
                    <custom-mask-input
                      v-model="formPersonal.document"
                      :mask="['###.###.###-##', '##.###.###/####-##']"
                      ref="document"
                      label="CPF ou CNPJ"
                      placeholder="CPF / CNPJ"
                      :customRules="docRules"
                      outlined
                    />
                  </v-col>

                  <v-col cols="12" md="6" class="pr-md-2">
                    <custom-mask-input
                      v-model="formAddress.zipCode"
                      :mask="'#####-###'"
                      ref="zipCode"
                      label="CEP"
                      placeholder="#####-###"
                      :customRules="zipCodeRules"
                      outlined
                    />
                  </v-col>

                  <v-col cols="12" md="6" class="pl-md-2">
                    <custom-mask-input
                      v-model="formAddress.state"
                      :mask="'AA'"
                      ref="state"
                      label="Estado"
                      placeholder="Sigla do seu estado"
                      outlined
                      @keypress="blockNumber($event)"
                    />
                  </v-col>

                  <v-col cols="12" md="6" class="pr-md-2">
                    <custom-input
                      v-model="formAddress.city"
                      ref="city"
                      label="Cidade"
                      placeholder="Informe sua cidade"
                      outlined
                      @keypress="blockNumber($event)"
                    />
                  </v-col>

                  <v-col cols="12" md="6" class="pl-md-2">
                    <custom-input
                      v-model="formAddress.neighborhood"
                      ref="neighborhood"
                      label="Bairro"
                      placeholder="Informe seu bairro"
                      outlined
                      @keypress="blockNumber($event)"
                    />
                  </v-col>

                  <v-col cols="12" md="10" class="pr-md-2">
                    <custom-input
                      v-model="formAddress.street"
                      ref="street"
                      label="Endereço"
                      placeholder="Escreva o nome da sua rua"
                      outlined
                    />
                  </v-col>

                  <v-col cols="12" md="2" class="pl-md-2">
                    <custom-mask-input
                      v-model="formAddress.number"
                      :mask="'######'"
                      ref="number"
                      label="Número"
                      placeholder="Número"
                      outlined
                    />
                  </v-col>

                  <v-col cols="12" md="6" class="pr-md-2">
                    <custom-input
                      v-model="formAddress.complement"
                      ref="complement"
                      label="Complemento"
                      placeholder="Complemento"
                      :required="false"
                      outlined
                    />
                  </v-col>

                  <v-col cols="12" md="6" class="pl-md-2">
                    <custom-input
                      v-model="formPersonal.profession"
                      ref="profession"
                      label="Profissão"
                      placeholder="Sua profissão"
                      outlined
                      @keypress="blockNumber($event)"
                    />
                  </v-col>

                  <v-col cols="12" md="6" class="pr-md-2">
                    <custom-mask-input
                      v-model="formPersonal.birthDate"
                      :mask="'##/##/####'"
                      placeholder="##/##/####"
                      ref="birthDate"
                      label="Data de Nascimento"
                      outlined
                    />
                  </v-col>

                  <v-col cols="12" md="6" class="pl-md-2">
                    <custom-mask-input
                      v-model="formPersonal.phone"
                      :mask="'(##) #####-####'"
                      ref="phone"
                      label="Celular"
                      placeholder="(##) #####-####"
                      outlined
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-window-item>

            <!-- bank wallets -->
            <v-window-item>
              <v-form v-model="valid[1]" ref="form2">
                <v-row align="center" no-gutters>
                  <v-col cols="12" md="3" lg="4" class="pr-md-2">
                    <custom-select
                      v-model="formPix.keyType"
                      ref="keyType"
                      label="Tipo de chave"
                      placeholder="Selecione o tipo da chave"
                      :items="pixTypes"
                      outlined
                    />
                  </v-col>

                  <v-col cols="10" md="5" lg="6" class="pl-0 px-lg-2">
                    <custom-input
                      v-model="formPix.pixKey"
                      ref="pixKey"
                      label="Chave Pix"
                      placeholder="Cadastre sua chave pix"
                      outlined
                    />
                  </v-col>

                  <v-col cols="2" md="3" lg="2" class="pl-2 d-flex justify-end">
                    <v-btn
                      color="primary"
                      class="mb-2"
                      block
                      height="48"
                      @click="addNewPixKey()"
                    >
                      Adicionar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>

              <v-list v-if="pixKeys.length > 0">
                <v-list-item v-for="(item, i) in pixKeys" :key="i">
                  <v-list-item-content>
                    <v-list-item-title>
                      <span>
                        {{ item.keyType }}
                      </span>
                    </v-list-item-title>

                    <v-list-item-subtitle>
                      <span> Tipo de chave </span>
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-content>
                    <v-list-item-title>
                      <span>
                        {{ item.pixKey }}
                      </span>
                    </v-list-item-title>

                    <v-list-item-subtitle>
                      <span> Chave pix </span>
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn icon @click="removeBankAccount(i)">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>

              <div
                v-else
                class="d-flex justify-center align-center text-center mb-4"
              >
                <v-card color="accent py-4 px-12" flat>
                  <span
                    class="overtext--text text-body-1 text-decoration-underline"
                  >
                    <span> Preencha os campos e adicione suas chaves pix </span>
                  </span>
                </v-card>
              </div>
            </v-window-item>

            <!-- living arrangements -->
            <v-window-item>
              <v-form v-model="valid[2]" ref="form3">
                <div
                  class="d-flex flex-column justify-center align-center text-center mb-6"
                >
                  <v-card
                    class="mb-4 dashed-border"
                    flat
                    @click="handleChangeImage()"
                  >
                    <v-avatar color="grey lighten-4" size="128">
                      <v-icon
                        v-if="
                          user.profilePhoto === 'assets/pics/profilephoto.jpg'
                        "
                        color="grey lighten-1"
                        size="48"
                      >
                        mdi-plus
                      </v-icon>

                      <v-img v-else :src="_profilePicture" />
                    </v-avatar>

                    <input
                      ref="uploader"
                      class="d-none"
                      type="file"
                      accept="image/*"
                      @change="submitProfileImage($event)"
                    />
                  </v-card>

                  <div class="text-h5 font-weight-black mb-2">
                    <span> Carregar uma foto </span>
                  </div>
                  <div class="text-body-1 subtext--text">
                    <span>
                      Faça o upload de uma foto para que outros usuários o
                      reconheçam facilmente
                    </span>
                  </div>
                </div>

                <v-row no-gutters>
                  <v-col cols="12">
                    <custom-money-input
                      v-model="formPersonal.incomeEstimate"
                      id="incomeEstimate"
                      ref="incomeEstimate"
                      label="Renda total familiar"
                      placeholder="Type Here"
                      outlined
                    />
                  </v-col>

                  <v-col cols="12">
                    <custom-select
                      v-model="formPersonal.liquidity"
                      :items="liquidityItems"
                      ref="liquidity"
                      label="Liquidez"
                      placeholder="Selecione"
                      itemText="label"
                      itemValue="value"
                      outlined
                    />
                  </v-col>

                  <v-col cols="12">
                    <custom-select
                      v-model="formPersonal.contactType"
                      :items="contactItems"
                      ref="contactType"
                      label="Preferência de contato"
                      placeholder="Selecione"
                      itemText="label"
                      itemValue="value"
                      outlined
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-window-item>

            <!-- receipts -->
            <v-window-item>
              <v-form v-model="valid[3]" ref="form4">
                <card-file-input
                  v-model="formPersonal.identityProof"
                  ref="receiptIdentity"
                  class="mb-4"
                  title="Envie sua identidade ou CNH"
                />

                <card-file-input
                  v-model="formAddress.addressProof"
                  ref="receiptAddress"
                  title="Envie seu comprovante de endereço"
                />

                <!--texto ja corrigido-->
                <v-checkbox class="ml-2" v-model="terms" :rules="rules">
                  <template v-slot:label>
                    <span>
                      Eu compreendo que minhas fotos e meus dados vão ser
                      análisados pela equipe da Earth guard e os dados são
                      protegidos por criptografia de ponta.
                    </span>
                  </template>
                </v-checkbox>
              </v-form>
            </v-window-item>
          </v-window>
        </v-card>

        <div class="d-flex">
          <v-btn
            v-if="step !== 0"
            class="text-none black--text"
            color="accent"
            depressed
            rounded
            large
            @click="handleBackStep()"
          >
            Voltar
          </v-btn>

          <v-spacer />

          <v-btn
            class="text-none"
            color="primary"
            depressed
            rounded
            large
            @click="handleNextStep()"
          >
            Continuar
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <loader-hover v-if="loading" />
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { displayAlert, blockNumber, revertDate, parseNumber } from "@/utils";
import { request } from "@/services";
import { getAddress } from "@/services/viacep";
import { cpf, cnpj } from "cpf-cnpj-validator";
import SentAnalysis from "@/components/account/SentAnalysis.vue";
let docInterval = null;

export default {
  data() {
    return {
      step: 0,
      loading: false,
      valid: [false, false, false, false],
      validDocument: false,
      validAddress: false,
      pixTypes: [
        {
          label: "CPF ou CNPJ",
          value: "CPF ou CNPJ",
        },
        {
          label: "Celular",
          value: "Celular",
        },
        {
          label: "E-mail",
          value: "E-mail",
        },
        {
          label: "Chave aleatória",
          value: "Chave aleatória",
        },
      ],
      liquidityItems: [
        {
          label: "Mensal",
          value: "monthly",
        },
        {
          label: "Semestral",
          value: "semiannual",
        },
        {
          label: "Anual",
          value: "yearly",
        },
      ],
      contactItems: [
        {
          label: "E-mail",
          value: "email",
        },
        {
          label: "Celular",
          value: "phone",
        },
        {
          label: "Whatsapp",
          value: "whatsapp",
        },
      ],
      formPersonal: {
        name: "",
        birthDate: "",
        phone: "",
        document: "",
        profession: "",
        incomeEstimate: "",
        liquidity: "",
        contactType: "",
        identityProof: null,
      },
      formAddress: {
        street: "",
        number: "",
        complement: "",
        zipCode: "",
        neighborhood: "",
        city: "",
        state: "",
        addressProof: null,
      },
      formPix: {
        keyType: "",
        pixKey: "",
      },
      pixKeys: [],
      terms: false,
    };
  },

  components: { SentAnalysis },

  beforeMount() {
    if (this.$store.getters.authorized) this.$router.push({ path: "/" });
  },

  watch: {
    ["formPersonal.document"](val) {
      if (val.length === 14 || val.length === 18) {
        if (docInterval) clearInterval(docInterval);

        docInterval = setTimeout(() => {
          this.loading = true;

          setTimeout(() => {
            this.handleDocumentValidation();
          }, 1000);
        }, 1000);
      }
    },

    ["formAddress.zipCode"](val) {
      if (val.length === 9) {
        this.loading = true;

        setTimeout(() => {
          this.handleAddressValidation();
        }, 1000);
      }
    },
  },

  computed: {
    ...mapState(["user"]),

    rules() {
      return [(v) => !!v || "Campo obrigatório"];
    },

    docRules() {
      return [
        (v) => !!v || "Campo obrigatório",
        (v) =>
          (v.length <= 14 ? v.length === 14 : v.length === 18) ||
          "Númeração inválida",
      ];
    },

    zipCodeRules() {
      return [
        (v) => !!v || "Campo obrigatório",
        (v) => v.length > 8 || "Informe um CEP válido",
      ];
    },

    _profilePicture() {
      return this.$store.getters.apiURL + this.user.profilePhoto;
    },

    _tabIndex() {
      return [
        {
          icon: "mdi-account-outline",
          label: "Dados Pessoais",
        },
        {
          icon: "mdi-wallet-outline",
          label: "Cadastro de chaves pix",
        },
        {
          icon: "mdi-text-account",
          label: "Outras informações",
        },
        {
          icon: "mdi-text-box-multiple-outline",
          label: "Documentos",
        },
      ];
    },
  },

  methods: {
    ...mapActions(["setUser", "setAuthorized"]),

    async submit() {
      try {
        this.loading = true;

        await this.submitPersonal();
        await this.submitAddress();
        await this.submitBankKey();

        await this.getUser();
        this.setAuthorized(true);
        this.$router.push({ path: "/profile" });
      } catch (err) {
        this.displayAlert(err.message, 1);
      } finally {
        this.loading = false;
      }
    },

    async submitPersonal() {
      const payload = {
        method: "uploadUserData",
        nome_razao: this.formPersonal.name,
        cpfCnpj: this.formPersonal.document.replaceAll(/[^0-9]/gim, ""),
        dataNascimento: revertDate(this.formPersonal.birthDate),
        telefone: this.formPersonal.phone.replaceAll(/[^0-9]/gim, ""),
        profissao: this.formPersonal.profession,
        estRenda: parseNumber(this.formPersonal.incomeEstimate),
        liquidez: this.formPersonal.liquidity,
        prefContato: this.formPersonal.contactType,
        docFrente: this.formPersonal.identityProof,
        docVerso: this.formPersonal.identityProof,
      };

      await request(payload);
    },

    async submitAddress() {
      const payload = {
        method: "uploadUserResidential",
        cep: this.formAddress.zipCode,
        estado: this.formAddress.state,
        rua: this.formAddress.street,
        numero: this.formAddress.number,
        cidade: this.formAddress.city,
        bairro: this.formAddress.neighborhood,
        complemento: this.formAddress.complement,
        imgComprovante: this.formAddress.addressProof,
      };

      await request(payload);
    },

    async submitBankKey() {
      for (let i = 0; i < this.pixKeys.length; i++) {
        const element = this.pixKeys[i];

        const payload = {
          method: "addUserBankKey",
          chave: element.pixKey,
          tipoChave: `Chave ${element.keyType}`,
        };

        await request(payload);
      }
    },

    async submitProfileImage(e) {
      try {
        this.loading = true;

        const payload = {
          method: "uploadProfilePhoto",
          Image: e.target.files[0],
        };

        await request(payload).then((res) => {
          this.getUser(0);
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async getUser() {
      try {
        const payload = {
          method: "getUserData",
        };

        await request(payload).then((res) => {
          this.setUser(res.userData);
        });
      } catch (err) {
        console.log(err);
      }
    },

    async handleValidateZipcode(value) {
      const zipCode = value.replaceAll(/[^0-9]/gim, "");
      let valid = true;

      if (zipCode.length < 8) return false;

      await getAddress(zipCode).then((res) => {
        if (res.data.erro) valid = false;
      });

      return valid;
    },

    async handleDocumentValidation() {
      const payload = this.formPersonal.document.replaceAll(/[^0-9]/gim, "");
      let result = false;

      if (payload.length === 11) result = cpf.isValid(payload);
      else if (payload.length === 14) result = cnpj.isValid(payload);

      if (!result) {
        this.validDocument = false;
        this.displayAlert("CPF/CNPJ informado inválido", 1);
      } else this.validDocument = true;

      this.loading = false;
    },

    async handleAddressValidation() {
      try {
        this.loading = true;

        await getAddress(this.formAddress.zipCode).then((res) => {
          if (!res.data.cep) {
            this.validAddress = false;
            this.displayAlert("CEP informado inválido", 1);

            return;
          }

          this.$refs.street.handleInput(res.data.logradouro);
          this.$refs.complement.handleInput(res.data.complemento);
          this.$refs.neighborhood.handleInput(res.data.bairro);
          this.$refs.city.handleInput(res.data.localidade);
          this.$refs.state.handleInput(res.data.uf);

          this.validAddress = true;
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    removeBankAccount(index) {
      this.pixKeys.splice(index, 1);
    },

    handleChangeImage() {
      window.addEventListener("focus", () => {}, { once: true });
      this.$refs.uploader.click();
    },

    async addNewPixKey() {
      await this.pixKeys.unshift({ ...this.formPix });

      this.$refs.keyType.handleInput("");
      this.$refs.pixKey.handleInput("");
    },

    handleSetAnalysis() {
      this.$refs.sentanalysis.open();
    },

    handleBackStep() {
      if (this.step !== 0) this.step -= 1;
    },

    async handleNextStep() {
      if (
        this.step === 3 &&
        (!this.$refs.receiptIdentity.validate() ||
          !this.$refs.receiptAddress.validate() ||
          !this.$refs.form4.validate())
      )
        return;

      if (this.step === 2 && !this.$refs.form3.validate()) return;

      if (this.step === 0) {
        if (!this.validDocument) await this.$refs.document.handleInput("");
        if (!this.validAddress) await this.$refs.zipCode.handleInput("");

        if (!this.$refs.form1.validate()) return;
      }

      if (this.step < 3) this.step = this.step + 1;
      else this.submit();
    },

    displayAlert,

    blockNumber,
  },
};
</script>

<style lang="scss" scoped>
.dashed-border {
  border-radius: 128px;
  border: 4px dashed #cccbd4;
}

.highlight {
  background-color: #f0effb;
  border: 2px solid #100a55;
}
</style>
